.leaflet-div-icon {
  background: transparent !important;
}

.leaflet-custom-marker-pin {
  color: $leaflet-default-marker-color;
  border-color: currentColor;

  &::before {
    box-shadow: 0 20px 10px 0 $leaflet-marker-shadow;
  }

  &::after {
    border-color: transparent;
    border-top-color: currentColor;
  }

  &.company-marker {
    box-shadow: 1px 1px 3px -1px $leaflet-marker-shadow;
  }
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar,
.leaflet-control.legend {
  border-color: $leaflet-tools-shadow;
  background: $theme-card;
}

.leaflet-bar a,
.leaflet-control-layers-expanded,
.leaflet-popup-content-wrapper,
.leaflet-popup-tip,
.leaflet-popup-content-wrapper a {
  background-color: $theme-card;
  color: $mat-text-color;
}

.leaflet-bar a:hover,
.leaflet-bar a:focus {
  background-color: $theme-card;
}

.leaflet-bar a + a {
  border: none;
}
