.sidenav-item {
  color: var(--menu-text-color);

  .subheading {
    color: var(--menu-text-color);
    font-family: $sidenav-item-subheading-typography-font-family;
  }

  .sidenav-item-link {
    color: inherit;
    font-family: $sidenav-item-typography-font-family;
    font-size: $sidenav-item-typography-font-size;
    font-weight: $sidenav-item-typography-font-weight;

    &:hover {
      background: rgba(0, 0, 0, .1);
      color: var(--menu-text-color);

      .text-icon {
        color: inherit;
      }
    }

    &.active {
      background: rgba(0, 0, 0, .2);
      color: var(--menu-text-color);

      .text-icon {
        color: inherit;
      }
    }

    .text-icon {
      color: $sidenav-item-text-icon-color;
    }
  }

  &.level-0 {
    &.dropdown-open {
      background: rgba(0, 0, 0, .1);

      > .sidenav-item-link {
        color: var(--menu-text-color);
      }
    }
  }

  &.level-1 {
    background: rgba(0, 0, 0, .1);

    .sidenav-item-link {
      padding-left: $sidenav-item-padding-left-level1;
    }
  }

  &.level-2 {
    background: rgba(0, 0, 0, .1);

    .sidenav-item-link {
      padding-left: $sidenav-item-padding-left-level2;
    }
  }

  &.level-3 {
    background: rgba(0, 0, 0, .1);

    .sidenav-item-link {
      padding-left: $sidenav-item-padding-left-level3;
    }
  }

  &.level-4 {
    background: rgba(0, 0, 0, .1);

    .sidenav-item-link {
      padding-left: $sidenav-item-padding-left-level4;
    }
  }
}
