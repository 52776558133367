@use 'sass:math';

html, body {
  min-height: 100%;
  height: 100%;
  font: var(--font-body-1);

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  box-sizing: border-box;
}

.fury-scrollblock {
  position: fixed;
  width: 100%;
}

.fury-collapsable-sidenav {
  overflow: visible !important;
  border-right: none !important;

  .mat-drawer-inner-container {
    overflow: visible !important;
  }
}

.fury-flex-form-field {
  .mat-form-field-infix {
    width: 0;
  }
}

h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em;
}

pre {
  margin: 0;
}

code {
  white-space: pre;
}

p {
  line-height: 1.5;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.quickpanel .mat-tab-body-content,
.quickpanel .mat-tab-body-wrapper,
.quickpanel mat-tab-body,
.quickpanel mat-tab-group {
  height: 100%;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging > *:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.quill-container {
  min-height: 300px;
  padding-bottom: 1.34375em;
}

.quill-container {
  padding-bottom: 1.34375em;
}

.ql-editor {
  word-break: break-word;
}

.no-data-yet-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  .mat-icon {
    margin-right: 8px;
  }

  &> div {
    font-weight: bold;
  }
}
