.report-panel-header {
  border-bottom-color: $mat-divider-color;
}

.report-panel-body {
  .report-panel-info {
    border-color: $mat-divider-color;
  }

  h2 {
    border-bottom-color: $mat-divider-color;
  }

  hr {
    border-top-color: $mat-divider-color;
  }

  .media {
    & > div {
      border-color: $mat-divider-color;

      .mat-icon {
        background: $mat-text-color-inverted;
      }
    }
  }

  .blue-border {
    border-color: $theme-color-primary;
  }

  .fake-live-mat-checkbox {
    .mat-checkbox-label {
      color: $theme-text;
    }
  }

  .suspect-info {
    & > div {
      border: 2px solid $theme-color-warn;
    }

    .warn {
      color: $theme-color-warn;
    }
  }
}

.note-container {
  & + .note-container {
    border-top: 1px solid $mat-divider-color;
  }
}

.report-panel-footer {
  border-top: 1px solid $mat-divider-color;
}
