.fury-tabs {

  .mat-tab-labels {
    background: $theme-app-bar;

    .mat-tab-label {

      &.mat-tab-label-active {
        color: $theme-text;
        opacity: 1;
      }
    }
  }

  .mat-tab-body-wrapper {
    flex: 1;
  }
}
