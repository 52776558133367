.fury-page-layout {
  display: block;
}

.fury-page-layout-simple {

  .fury-page-layout-content {
    padding-top: $padding;
    padding-bottom: $padding;
  }
}

.fury-page-layout-card {
  padding-bottom: $padding;

  .fury-page-layout-header {
    margin-bottom: -$page-layout-toolbar-height;
    padding-bottom: $page-layout-toolbar-height;
  }

}

.fury-page-layout-header {
  height: $page-layout-height - $page-layout-toolbar-height - $page-layout-toolbar-height;
  padding-left: $padding;
  padding-right: $padding;
  display: flex;
  flex-direction: row;
  place-content: center space-between;
  align-items: center;

  button + button {
    margin-left: 5px;
  }
}

.fury-page-layout-content {
  position: relative;
  display: block;
  padding-left: $padding;
  padding-right: $padding;

  @include layout-bp(lt-md) {
    padding-left: $padding-md;
    padding-right: $padding-md;
  }

  .fury-page-layout-content-width {
    display: flex;
    max-width: 100%;
    width: 100%;
    align-self: center;
    flex-direction: column;

    &.fury-page-layout-content-narrow {
      max-width: 650px;
    }

    &.fury-page-layout-content-middle {
      max-width: 1024px;
    }
  }
}

.fury-page-layout-table {
  .fury-page-layout-content {
    display: block;
    padding: 0;
  }
}

@media (max-width: 639px) {
  .fury-page-layout-content {
    padding-left: 12px;
    padding-right: 12px;
  }
}
