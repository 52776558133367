.fury-page-layout {

}

.fury-page-layout-header {
  background: $page-layout-header-background;
  //  background: #f5f5f5;
  border-bottom: 1px solid $page-layout-header-border-color;

  .title {
    color: $theme-text;
  }
}
