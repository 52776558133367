.ql-html {
  padding: 0 !important;

  &:before {
    font-family: 'Material Icons Outlined';
    content: '\eb7e';
    font-size: 24px;
  }
}

.ql-custom-tooltip {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;

  textarea {
    width: 100%;
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
      flex: 1 1 50%;
    }
  }
}

.ql-syntax {
  background: #eee;
  padding: 10px 15px;
  margin: 10px 0;
  overflow: auto;
}

.ql-editor ol,
.fury-card-content ol {
  padding: 0;
  margin: 0;
  list-style-type: none;

  li:not(.ql-direction-rtl) {
    padding-bottom: 0.3em;
    padding-left: 2em;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;

    &:before {
      content: counter(list-0, decimal) '. ';
      margin-left: -2em;
      margin-right: 0.5em;
      text-align: right;
      display: inline-block;
      white-space: nowrap;
      width: 1.5em;
    }

    &.ql-indent-1:not(.ql-direction-rtl) {
      padding-left: 3em;
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      counter-increment: list-1;

      &:before {
        content: counter(list-1, disc) '';
      }

      .ql-indent-2:not(.ql-direction-rtl) {
        padding-left: 5em;
        counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-2;

        &:before {
          content: counter(list-2, disc) '';
        }
      }
    }
  }
}
