.mat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-badge-content.mat-primary {
  background-color: var(--primary-color);
  color: var(--primary-color-text)
}

.mat-button.mat-secondary,
.mat-raised-button.mat-secondary,
.mat-badge-content.mat-secondary {
  background-color: var(--secondary-color);
  color: var(--secondary-color-text);
}

.mat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-badge-content.mat-accent {
  background-color: #DD2815;
  color: #ffffff;
}

.mat-button.mat-default,
.mat-raised-button.mat-default,
.mat-badge-content.mat-primary {
  background-color: #cccccc;
  color: #333333;
}

.mat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-badge-content.mat-warn, {
  background-color: #FFA500;
  color: #333333;
}

.mat-flat-button.transparent {
  background-color: transparent;
  color: #333;
}

.mat-button.mat-primary[disabled],
.mat-raised-button.mat-primary[disabled],
.mat-button.mat-secondary[disabled],
.mat-raised-button.mat-secondary[disabled],
.mat-button.mat-accent[disabled],
.mat-raized-button.mat-accent[disabled],
.mat-button.mat-default[disabled],
.mat-raised-button.mat-default[disabled],
.mat-button.mat-warn[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-icon-button[disabled] {
  opacity: 0.6;
  box-shadow: none;
}


.mat-dialog-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mat-raised-button {
    margin: -7px 0;
  }
}

.mat-dialog-content {
  h2 {
    font-size: 14px;
    font-weight: 500;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin: 10px 0 16px 0;
    padding: 0 0 10px 0;
    line-height: 15px;

    .mat-icon {
      font-size: 16px;
      width: 16px;
      height: 16px;
      vertical-align: bottom;
      min-width: 16px;
      min-height: 16px;
    }
  }

  p {
    margin: 0;
  }

  .mat-form-field {
    display: block;
    width: 100%;

    + .mat-checkbox {
      margin-top: 16px;
    }
  }

  .entry {
    & + .entry {
      margin-top: 4px;
      border-top-width: 1px;
      border-top-style: solid;
      padding-top: 4px;
    }

    h3 {
      font-size: 14px;
      margin: 0;
    }

    p {
      margin: 0;
      font-style: italic;
    }
  }
}

.mat-dialog-actions {
  padding: 20px 0 0 0;
  margin-bottom: 0;
  min-height: auto;
  flex-direction: column;
  grid-gap: 16px;

  @media (min-width: 960px) {
    flex-direction: row;
  }

  .mat-raised-button {
    width: 100%;
    flex: 1;
  }

  .mat-raised-button,
  .mat-raised-button + .mat-raised-button {
    margin: 0;
  }
}

.fake-mat-form-field {
  box-sizing: border-box;
  position: relative;
  padding-bottom: 1.34375em;
  font: 400 14px / 1.125 var(--font-family);

  .fake-mat-form-field-content {
    padding: 1.75em 0.75em 0.75em 0.75em;
    position: relative;
    flex-direction: column;
    display: flex;
    flex: 1 1 auto;

    &:after {
      content: '';
      border-bottom-width: 1px;
      border-bottom-style: solid;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:hover:after {
      border-bottom-width: 2px;
    }
  }

  mat-label,
  mat-error {
    scale: 0.75;
    position: absolute;
    top: 0.4em;
    width: 133.3%;
    overflow: hidden;
    padding: 0 1em;
    left: -16.6%;
    box-sizing: border-box;
    z-index: 10;
  }

  mat-error {
    top: initial;
    bottom: 0;
  }

  &.quill {
    padding: 0;

    mat-label {
      top: 0;
    }

    .fake-mat-form-field-content {
      padding: 16px 0 0 0;
    }

    .fake-mat-form-field-content:after {
      display: none;
    }
  }
}

.companies-selector {
  width: 300px;
}

.mat-option.dropdown-search {
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 1;

  .mat-pseudo-checkbox {
    display: none;
  }

  .mat-option-text {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;

      input {
        height: 3em;
        margin: 0;
        border: 0;
        padding: 0 16px;
        flex-grow: 1;

        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}

.mat-option.selected-companies {
  .mat-pseudo-checkbox {
    display: none;
  }

  .mat-option-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    div {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .mat-icon {
      margin: 0;
    }
  }
}

.mat-option.selected-companies + .mat-option:not(.selected-companies) {
  border-top-width: 1px;
  border-top-style: solid;
}

.mat-option.hide-checkbox {
  .mat-pseudo-checkbox {
    display: none;
  }
}


.mat-select-panel.week-selector-container {
  max-width: 340px;
}

.mat-option.mat-option-disabled.week-selector {
  .mat-option-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    span.input {
      line-height: 1em;
      border-width: 1px;
      border-style: solid;
      border-radius: 5px;

      input {
        margin: 0;
        line-height: 1em;
        border: 1px solid transparent;
        padding: 5px 8px;
        width: 25px;
      }

      &:hover {
        border-color: currentColor;

        input {
          border-color: currentColor;
        }
      }
    }
  }
}

.mat-option.week-remove-selector {
  .mat-option-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .mat-icon {
    margin: 0;
  }
}

.report-panel-body {
  mat-button-toggle-group {
    margin-bottom: 16px;
    border: 0;

    &.mat-button-toggle-group-appearance-standard {
      .mat-button-toggle {
        border-radius: 4px 0 0 4px;
        border-width: 2px;
        border-style: solid;
      }

      .mat-button-toggle + .mat-button-toggle {
        border-radius: 0 4px 4px 0;
        border-left: 0;
      }

      .mat-button-toggle-label-content {
        line-height: 34px;
      }
    }

    mat-button-toggle {
      font-weight: bold;
    }
  }
}


.address-dropdown-position {
  position: relative;
}

.address-dropdown-container {
  position: relative;

  .address-dropdown {
    position: absolute;
    z-index: 1;
    top: 0;
    right: -20px;
    left: -20px;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom-width: 1px;
      border-bottom-style: solid;

      p {
        padding: 5px 10px;
        font-size: 13px;
        margin: 0;
      }
    }

    .mat-selection-list {
      padding: 0;
      max-height: 200px;
      overflow-y: scroll;
    }
  }
}
