@use '@angular/material' as mat;

.fury-card {
  background: $theme-card;
  color: $theme-text;

  @include mat.elevation($elevation);

  .fury-card-header-subheading {
    color: $theme-secondary-text;
  }
}
