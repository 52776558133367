.leaflet-container {
  font-family: inherit !important;
  font-size: inherit !important;
}

.leaflet-div-icon {
  border: none !important;
}

.leaflet-custom-marker-pin {
  position: absolute !important;
  border-radius: 50%;
  border-width: 8px;
  border-style: solid;
  width: 24px;
  height: 24px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -15px;
    width: 26px;
    height: 4px;
    rotate: -43deg;
    z-index: -1;
  }

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: -30px;
    left: -6px;
    border-width: 10px;
    border-style: solid;
    border-top-width: 17px;
  }

  &.company-marker {
    border-width: 9px !important;;
    width: 18px;
    height: 18px;

    &::before,
    &::after {
      display: none;
    }
  }
}

.leaflet-control.legend {
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  padding: 10px 15px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .legend-toggle {
      margin: -10px;
      padding: 5px;
      width: 20px;
      height: 20px;
      line-height: 1;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .content {
    overflow: hidden;
    margin-top: 5px;

    &.collapsed {
      height: 0;
      margin-top: 0;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-right: 6px;
      }
    }
  }
}
