@import "./var-helpers";

$sidenav-background: $theme-app-bar !default;
$sidenav-color: $theme-text !default;
$sidenav-toolbar-background: $theme-app-bar !default;
$sidenav-logo-color: $theme-text !default;

$sidenav-item-color: $sidenav-color !default;
$sidenav-item-color-hover: $theme-text !default;
$sidenav-item-background-hover: $theme-background-hover !default;
$sidenav-item-text-icon-color: $theme-hint-text !default;

$sidenav-item-subheading-color: $theme-secondary-text !default;

$toolbar-background: $theme-app-bar !default;
$toolbar-search-background: $theme-card !default;

$page-layout-header-background: $theme-app-bar !default;
$page-layout-header-border-color: $theme-card !default;
$page-layout-header-input-background: rgba(80, 80, 80, .5) !default;
$page-layout-header-input-border: rgba(255, 255, 255, 1) !default;

$mat-background-color-disabled: rgba(255, 255, 255, .05) !default;
$mat-text-color-disabled: rgba(255, 255, 255, .6) !default;
$mat-text-background-color: rgba(255, 255, 255, .1) !default;
$mat-text-color: rgba(255, 255, 255, .87) !default;
$mat-text-background-color-inverted: rgba(255, 255, 255, .87) !default;
$mat-text-color-inverted: rgba(0, 0, 0, .87) !default;
$mat-divider-color: rgb(171, 171, 171);
$mat-text-label-color: rgba(255, 255, 255, 0.7) !default;
$table-divider-color: rgba(80, 80, 80, 1) !default;

$mat-button-black-text-color: #333333 !default;
$mat-button-black-background-color: #cccccc !default;

$loading-overlay-background: rgba(0, 0, 0, .40) !default;

$link-color: white !default;

$table-deleted-row-background-color: rgba(255, 0, 0, .1) !default;
$table-deleted-row-text-color: rgba(255, 255, 255, .6) !default;
$table-disabled-row-background-color: rgba(100, 100, 100, .1) !default;
$table-disabled-row-text-color: rgba(255, 255, 255, .6) !default;

@import "./default";

.mat-table .mat-column-status_id {
  opacity: 0.5;
}

fury-sidenav .sidenav {
  background: $sidenav-background;
}

.sidenav-item .sidenav-item-link.active {
  background: $theme-card;
}
