.mat-table {
  &.statistics {
    .mat-header-row {
      border-bottom-color: $page-layout-header-border-color;

      .mat-cell {
        color: $mat-text-color;
      }
    }

    .mat-row {
      border-bottom-color: $table-divider-color;
    }
  }

  .mat-header-row,
  .paginator,
  .table-paginator,
  .mat-footer-row {
    background: $page-layout-header-background;
  }

  .mat-row:hover {
    background: $theme-background-hover;
  }

  .mat-cell.actions-cell, .mat-header-cell.actions-cell {
    button {
      color: $theme-icon;
    }
  }

  .mat-cell.image-cell, .mat-header-cell.image-cell {
    img {
      background: $theme-app-bar;
    }
  }

  .mat-cell {
    span {
      &.saved-error {
        color: $theme-color-warn;
      }

      &.saved-success {
        color: #9ac43b;
      }
    }
  }
}
