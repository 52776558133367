.knowledge-base {
  .kb-group {
    background: $theme-card;

    a {
      color: $link-color;

      &.kb-link {
        color: $theme-text;
      }
    }
  }
}

.knowledge-base-faq {
  border-top-color: $page-layout-header-border-color;
  background: $page-layout-header-background;

  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    border-bottom-color: $page-layout-header-border-color;
  }

  mat-expansion-panel-header {
    &.mat-expanded {
      border-bottom-color: $page-layout-header-border-color;
    }
  }
}
